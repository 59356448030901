export const en = {
    navbar: {
        philosophy: "Philosophy",
        incubator: "Incubator",
        works: "Works",
        contacts: "Contacts",
        getInTouch: "Get In Touch",
    },
    index: {
        hero: {
            title: 'Empowering <span class="text-mk-primary">Retail</span>,<br><span class="text-mk-primary">Brand</span> and <span class="text-mk-primary">Consumer</span><br>synergy through <br> <span class="text-mk-primary">Digital Solutions</span>',
            payoff: "Market3rs is your martech incubator, nurturing sustainable & innovative digital projects, and empowering them with the knowledge and resources to grow.",
            button: "Start Your Journey Today"
        },
        services: {
            title: "Our Services",
            payoff: "Our incubator agency brings together 4 seasoned brands, masters of their craft, ready to elevate your success.",
            readMore: "Start Now",
            "1up": {
                title: "Client engagement through gamification",
                payoff: "Level up your customer engagement with our gaming-inspired tactics."
            },
            power2brands: {
                title: "Brand Equity Monetization",
                payoff: "Turn your reputation into profit."
            },
            mugo: {
                title: "Driving sustainable consumer action",
                payoff: "Turn sustainability into an engaging journey for your customers."
            },
            smartPromotion: {
                title: "Personalized Targeting Solutions",
                payoff: "Reach the right people, every time with innovative, tailored digital solutions."
            }
        },
        philosophy: {
            title: "How we do it?",
            payoff: "We succeed best when we truly believe in the projects we take on. For us, the magic happens when our three core pillars -  innovation, expertise, and collaboration come together.",
            item1: {
                title: "Digital First",
                payoff: "We focus on digital because it's what we love and what we do best. We bring ideas to life with technology that meets the actual needs.",
            },
            item2: {
                title: "Supporting the Market",
                payoff: "We create projects that build stronger relationships between retailers, brands, and consumers, making the market work better for everyone.",
            },
            item3: {
                title: "Specialized for Success",
                payoff: "We concentrate on specific, vertical projects and work with experts who focus on what truly matters to make a difference.",
            }
        },
        works: {
            title: "Works",
            seeAll: "See all",
            item1: {
                title: "From physical to digital",
            },
            item2: {
                title: "Coupon system",
            }
        },
        clients: {
            title: "Our clients"
        },
    },
    newsletter: {
        title: "Never miss the latest news",
        placeholder: "Enter your email",
        messages: {
            missingEmail: "Please enter a valid email",
            captchaErrorMessage: "The captcha was not solved correctly, please retry",
            successMessage: "You've been successfully subscribed to our newsletter",
            errorMessage: "There was an error, please retry",
        }
    },
    contacts: {
        tag: "Contacts",
        title: "Get in Touch",
        fillForm: "Fill in the form or email us at:",
        headquarters: "Main Office:",
        form: {
            name: "NAME",
            email: "EMAIL",
            message: "MESSAGE",
            send: "Send"
        },
        messages: {
            missingName: "Please enter your name",
            missingEmail: "Please enter a valid email",
            missingMessage: "Please enter a message",
            successMessage: "Message sent successfully",
            errorMessage: "There was an error, please retry",
            captchaErrorMessage: "The captcha was not solved correctly, please retry"
        }
    },
    philosophy: {
        hero: {
            title: 'True <span class="text-mk-primary">Excellence</span>,<br>requires <span class="text-mk-primary">Skill, Focus</span> <br> and <span class="text-mk-primary">the ability to Dream</span>',
            payoff: "That's why we make sure our team has the time and space to pursue their passions and goals.",
            button: "Let's make your dreams come true"
        },
        section1: {
            main: "We identify ourselves as a <b>martech incubator</b>. At Market3rs, we handpick the most <b>synergetic</b> projects that resonate with our <b>values</b> and provide them with the expertise and resources they need to take off.",
            synergy: {
                title: "Synergy",
                text: "Finding common ground that makes things work better, and also spotting areas where we can expand and grow."
            },
            martech: {
                title: "Martech",
                text: "The latest tech for smarter marketing."
            },
            values: {
                title: "Values",
                text: "We believe in projects that embrace digital, are sustainable, innovative, and foster collaboration. These values are essential to us."
            },
            incubator: {
                title: "Incubator",
                text: "A nurturing space where accelerator elements for growth are provided."
            }
        },
        workModel: {
            title: "Our Work Model",
            section1: "Imagine a core team that's packed with marketing know-how, a worldwide sales network, tech talent, and financial muscle.",
            section2: "Around this core, you'll find the actual projects, organized as growing companies or divisions that are ready to spin off on their own.",
            card: {
                title: "This Model Is Well Represented By The Symbol Of Market3rs: The Star!",
                text: "This model is all about working together to save money and get things done quickly. Plus, it opens the door for joint projects, bigger markets, and more opportunities to learn from each other."
            },
            box: {
                stakeholders: {
                    title: "Stakeholders",
                    text: "They're at the heart of our projects. We make sure everyone wins, from start to finish. That's why we clearly define our stakeholders – retailers, brands, and consumers – so we can measure the impact."
                },
                mission: {
                    title: "Mission",
                    text: "Our goal is to build stronger, deeper, and lasting relationships with our stakeholders."
                },
                values: {
                    title: "Values",
                    text: "We're all about going digital, choosing sustainability, and always thinking outside the box."
                }
            }
        }
    },
    incubator: {
        tag: "Incubator",
        title: "Where Dreams Come <span class=\"text-mk-primary\">True</span>",
        contact: "Your contact person for",
    },
    works: {
        tag: "Works",
        title: "Our <span class=\"text-mk-primary\">Case studies</span>",
    },
    company: {
        button: "Visit Website",
        aboutUs: "About Us",
        keyPerson: "Key Person",
        caseStudies: {
            title: "Case studies",
        },
        faq: {
            title: "FAQ"
        }
    }
}
