import {en} from "~/translations/en";
import {it} from "~/translations/it";

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: en,
        it: it
    }
}))
