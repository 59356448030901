export const it = {
    navbar: {
        philosophy: "Filosofia",
        incubator: "Incubatore",
        works: "Lavori",
        contacts: "Contatti",
        getInTouch: "Contattaci",
    },
    index: {
        hero: {
            title: 'Sviluppiamo il legame tra <span class="text-mk-primary">Retailer</span>,<br><span class="text-mk-primary">Brand</span> e <span class="text-mk-primary">Consumatori</span><br> tramite <br> <span class="text-mk-primary">soluzioni digitali</span>',
            payoff: "Market3rs è un incubatore martech, che sviluppa progetti digitali innovativi e sostenibili, alimentandoli con le competenze e le risorse necessarie per la loro crescita.",
            button: "Inizia Oggi Il Tuo Viaggio"
        },
        services: {
            title: "I nostri servizi",
            payoff: "Nel nostro incubatore abbiamo riunito 4 progetti innovativi, con una competenza specifica e verticale, pronti a supportare il vostro successo.",
            readMore: "Inizia",
            "1up": {
                title: "Ingaggio digitale dei consumatori",
                payoff: "Intensifica la relazione con i consumatori tramite le nostre strategie derivate dal mondo del gaming."
            },
            power2brands: {
                title: "Monetizzazione del Marchio",
                payoff: "Genera ricavi capitalizzando la reputazione costruita dal tuo marchio."
            },
            mugo: {
                title: "Scelte Consapevoli e Sostenibili",
                payoff: "Rendi la riduzione dell'impatto ambientale un obbiettivo condiviso e una chiave di ingaggio degli utenti."
            },
            smartPromotion: {
                title: "Tecnologia per Promozioni Personalizzate",
                payoff: "Raggiungi il tuo target con messaggi efficaci, tramite l'analisi dei dati e l'uso dei canali digitali."
            }
        },
        philosophy: {
            title: "Come lo facciamo?",
            payoff: "I risultati sono eccellenti quando crediamo profondamente nei progetti che sviluppiamo. Per noi, l'alchimia funziona quando sono compresi tre principi fondamentali: Innovazione, Competenza e Lavoro di squadra",
            item1: {
                title: "Innanzitutto, digitali",
                payoff: "Ci concentriamo sul digitale prima di tutto perché lo amiamo ed è ciò che facciamo meglio. Realizziamo idee tramite la tecnologia che risponde alle esigenze del nostro mercato.",
            },
            item2: {
                title: "Sviluppiamo progetti che supportano il mercato",
                payoff: "Creiamo strumenti che aiutano a rafforzare il rapporto tra Retailer, Marchi e Consumatori, supportando i risultati del mercato.",
            },
            item3: {
                title: "Valore della Specializzazione",
                payoff: "Ci concentriamo su progetti verticali, permettendo ad un team di esperti di dedicare tutte le proprie energie a ciò che realmente crea un impatto.",
            }
        },
        works: {
            title: "Lavori",
            seeAll: "Guardali tutti",
            item1: {
                title: "Dal fisico al digitale",
            },
            item2: {
                title: "Mugo Retail",
            }
        },
        clients: {
            title: "I nostri clienti"
        },
    },
    newsletter: {
        title: "Non perderti le ultime notizie",
        placeholder: "Registra la tua email",
        messages: {
            missingEmail: "Inserisci una email valida",
            captchaErrorMessage: "Il captcha non è stato risolto correttamente, per favore riprova",
            successMessage: "Ti sei iscritto correttamente alla nostra newsletter",
            errorMessage: "Si è verificato un errore, per favore riprova",
        }
    },
    contacts: {
        tag: "Contatti",
        title: "Contattaci",
        fillForm: "Compila il modulo o scrivici all'indirizzo:",
        headquarters: "Sede operativa:",
        form: {
            name: "NOME",
            email: "EMAIL",
            message: "MESSAGGIO",
            send: "Invia"
        },
        messages: {
            missingName: "Inserisci il tuo nome",
            missingEmail: "Inserisci una email valida",
            missingMessage: "Inserisci un messaggio",
            successMessage: "Messaggio inviato correttamente",
            errorMessage: "Si è verificato un errore, per favore riprova",
            captchaErrorMessage: "Il captcha non è stato risolto correttamente, per favore riprova"
        }
    },
    philosophy: {
        hero: {
            title: 'La vera <span class="text-mk-primary">Eccellenza</span>,<br>richiede <span class="text-mk-primary">Competenza, Concentrazione</span> <br> e <span class="text-mk-primary">la capacità di Sognare</span>',
            payoff: "Questo è il motivo per cui ci assicuriamo che la nostra squadra abbia il tempo e le risorse per raggiungere i propri obbiettivi.",
            button: "Realizza i tuoi sogni"
        },
        section1: {
            main: "Vediamo noi stessi come un <b>incubatore martech</b>. Market3rs seleziona i progetti più <b>sinergici</b> e in linea con i nostri <b>valori</b>, per alimentarli con le risorse di cui hanno bisogno per avere affermarsi.",
            synergy: {
                title: "Sinergie",
                text: "Punti di contatto che permettono di ottimizzare il lavoro, ma anche aree di possibile crescita e innovazione."
            },
            martech: {
                title: "Martech",
                text: "La tecnologia più attuale per progetti di marketing evoluto."
            },
            values: {
                title: "Valori",
                text: "Noi crediamo in progetti fondati sul digitale, orientati alla sostenibilità e mirati alla collaborazione. Questi valori sono per noi essenziali."
            },
            incubator: {
                title: "Incubatore",
                text: "Uno spazio sicuro in cui gli elementi necessari alla crescita sono resi disponibili."
            }
        },
        workModel: {
            title: "Il Nostro Modello",
            section1: "Immagina un nucleo di collaboratori che racchiude competenze di marketing, una rete commerciale internazionale, talenti tecnologici e risorse finanziarie.",
            section2: "Attorno a questo nucleo, ci sono i veri progetti, strutturati come aziende in crescita o divisioni pronte a distaccarsi per divenire indipendenti.",
            card: {
                title: "Questo Modello É Ben Rappresentato Dal Logo di Market3rs: La Stella!",
                text: "Questo modello è mirato alla realizzazione efficiente e veloce dei progetti. Inoltre, crea opportunità per progetti congiunti, mercati più ampi e scambio di competenze."
            },
            box: {
                stakeholders: {
                    title: "Stakeholders",
                    text: "Sono il cuore dei nostri progetti. Vogliamo essere certi di generare valore per tutti loro. Per questo li definiamo chiaramente – Retailer, Marchi e Consumatori – per essere in grado di misurare l'impatto."
                },
                mission: {
                    title: "Missione",
                    text: "Il nostro obbiettivo è di costruire una relazione forte, intensa e duratura tra gli stakeholders."
                },
                values: {
                    title: "Valori",
                    text: "Siamo per il digitale, per la sostenibilità e per pensare sempre fuori dagli schemi."
                }
            }
        }
    },
    incubator: {
        tag: "Incubatore",
        title: "Dove I Sogni Diventano <span class=\"text-mk-primary\">Realtà</span>",
        contact: "I nostri esperti",
    },
    works: {
        tag: "Lavori",
        title: "Le Nostre <span class=\"text-mk-primary\">Esperienze</span>",
    },
    company: {
        button: "Visita Il Sito Web",
        aboutUs: "Su Di Noi",
        keyPerson: "Persona Di Riferimento",
        caseStudies: {
            title: "Esperienze",
        },
        faq: {
            title: "FAQ"
        }
    }
}
